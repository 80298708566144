//export const USERS_URL = "http://192.168.0.16";
//export const STATS_URL = "http://192.168.0.30";
//export const CONTENT_URL = "http://192.168.0.17";

export const USERS_URL = "https://users.unitedbrew-sf.site";
export const STATS_URL = "https://stats.unitedbrew-sf.site";
export const CONTENT_URL = "https://backend.unitedbrew-sf-ontrade.com";

//export const USERS_URL = "https://users.heineken.site";
//export const STATS_URL = "https://stats.heineken.site";
//export const CONTENT_URL = "https://offbackend.sf-heineken.com";

export const USERS_API_URL = USERS_URL + "/api";
export const CONTENT_API_URL = CONTENT_URL + "/api";
export const STATS_API_URL = STATS_URL + "/api";
