<template>
  <layout-auth>
    <div class="start wrapper formwidth">
      <div class="welcome form">
        <p class="LoginMessage">
          Добро пожаловать,
          <span v-html="fullName"></span>
        </p>
      </div>
      <transition name="fade">
        <form class="content-loader" v-if="contentLoader">
          <div v-if="!buttonsDisabled">
            <p>Доступно обновление контента</p>
            <button name="load_confirm" @click.prevent="getOfflineContent">
              Загрузить
            </button>
            <button name="load_postpone" @click.prevent="postpone" v-if="cncl">
              Позже
            </button>
            <div v-if="homeErrors.length">
              <p
                class="LoginError"
                :key="key"
                v-for="(error, key) in homeErrors">
                {{ error }}
              </p>
            </div>
          </div>
          <div class="loading-info" v-if="phase !== ''">
            <lottie
              :options="defaultOptions"
              :height="70"
              :width="70"
              v-on:animCreated="handleAnimation"
              class="loader-graphics" />
            <span class="loadingProgress" v-text="loadingProgress"></span>
          </div>
          <p v-if="phase !== ''">
            Пожалуйста, дождитесь окончания процесса загрузки
          </p>
        </form>
      </transition>
      <transition name="fade">
        <form
          class="form form-region"
          @submit.prevent="start"
          v-if="regionChooser">
          <p>Выберите доступный регион:</p>
          <sui-dropdown
            placeholder="Регион"
            selection
            :options="selectOptions"
            v-model="region" />
          <div class="InputfieldSubmit" v-bind:class="{ active: isLoading }">
            <button type="submit">Start</button>
          </div>
        </form>
      </transition>
      <div v-if="phase == '' || phase == 'contents'">
        <p>
          <a
            class="with-icon change-password"
            href="profile"
            @click.prevent="profile">
            Изменить пароль
          </a>
        </p>
        <p>
          <a class="with-icon logout" href="logout" @click.prevent="logout"
            >Выйти</a
          >
        </p>
      </div>
      <div v-else>
        <p>
          <a class="with-icon cancel" href="home" @click.prevent="cancel">
            Отмена
          </a>
        </p>
      </div>
    </div>
  </layout-auth>
</template>

<script>
import Lottie from "vue-lottie";
import * as loaderAnimation from "@/assets/data.json";
import LayoutAuth from "@/Layouts/LayoutAuth.vue";
import * as CONFIG from "@/config";

export default {
  name: "Home",
  components: {
    lottie: Lottie,
    LayoutAuth,
  },
  data() {
    return {
      region: this.$store.state.region,
      cncl: this.$store.state.modified,
      phase: "",
      buttonsDisabled: false,
      contentLoader: false,
      regionChooser: false,
      homeErrors: [],
      defaultOptions: {
        animationData: loaderAnimation.default,
        autoplay: false,
      },
      isLoading: false,
      loadingProgress: "",
    };
  },
  mounted() {
    if (window.cordova) {
      if (window.navigator.onLine) {
        this.initPushNotifications();
        this.isLoading = true;
        if (this.$store.state.userData === null) this.logout(true);
        this.$store
          .dispatch("checkForMods")
          .then((result) => {
            this.isLoading = false;
            console.log("Insomnia off");
            window.plugins.insomnia.allowSleepAgain();
            if (result) {
              this.contentLoader = true;
              this.buttonsDisabled = false;
            } else {
              this.loadContent();
            }
          })
          .catch((err) => {
            this.phase = "";
            this.isLoading = false;
            this.regionChooser = true;
            this.onLoadError(err);
            if (
              typeof err !== "undefined" &&
              typeof err.response.status !== "undefined" &&
              err.response.status === 401
            ) {
              this.logout(true);
            }
          });
      } else {
        this.loadContent();
      }
    } else {
      this.$store
        .dispatch("checkForMods")
        .then((needLoading) => {
          if (needLoading) {
            this.contentLoader = true;
            this.buttonsDisabled = true;
            this.phase = "contents";
            this.loadContent();
          } else {
            this.phase = "";
            this.isLoading = false;
            this.regionChooser = true;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.onLoadError(err);
          /*if (err.response.status === 401) {
            this.logout();
          }*/
        });
    }
  },
  computed: {
    fullName() {
      return this.$store.state.userData
        ? [
            this.$store.state.userData.firstName,
            this.$store.state.userData.lastName,
          ].join("&nbsp;")
        : "";
    },
    selectOptions() {
      return this.$store.state.userData.userRegion;
    },
  },
  methods: {
    loadContent(firstTime = false) {
      this.$store
        .dispatch("fetchTOC")
        .then((response) => {
          this.phase = "";
          this.isLoading = false;
          this.regionChooser = true;
          if (window.cordova) {
            this.$store.dispatch("fetchNewUpdates");
            this.$store.commit("setTOC", {
              data: JSON.parse(response),
            });
          }
        })
        .catch((err) => {
          this.phase = "";
          this.isLoading = false;
        });
    },
    initPushNotifications: function () {
      const push = PushNotification.init({
        ios: {
          alert: "true",
          badge: "true",
          sound: "true",
        },
      });

      push.on("registration", (data) => {
        if (!this.$store.state.appToken) {
          this.$store.dispatch("registerAppUser", {
            appToken: data.registrationId,
          });
        }
      });

      push.on("notification", (data) => {
        if (data.additionalData.foreground) {
          if (data.count) {
            cordova.plugins.notification.badge.set(data.count);
          }
          if (data.sound) {
            const media = new Media("media/chime.wav");
            media.play({ playAudioWhenScreenIsLocked: false });
          }
          if (data.message) {
            navigator.notification.confirm(
              data.message,
              function (btn) {
                if (btn == 1) {
                  this.$router.push({ path: "/" });
                }
              },
              "Обновление",
              ["Обновить", "Отмена"]
            );
          }
        } else {
          this.$router.push({ path: "/" });
        }
      });

      push.on("error", function (e) {});
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      if (window.cordova) {
        this.anim.playSegments([0, 24], true);
        let borderref = $("#iconborder path");

        this.borderCompl = borderref.clone().insertAfter(borderref);
        this.borderCompl.attr("stroke", "#005f20");
        this.borderCompl.attr("stroke-dasharray", "0 1000");

        this.border = borderref.clone().insertAfter(borderref);
        this.border.attr("stroke", "#1ea434");
        this.border.attr("stroke-dasharray", "0 1000");

        this.borderLength = this.border.get(0).getTotalLength();
        console.log(`borderLength=${this.borderLength}`);
      } else {
        this.anim.playSegments([25, 25 + 24], true);
      }
    },
    cancel() {
      if (window.cordova) {
        console.log("Insomnia off");
        window.plugins.insomnia.allowSleepAgain();
        if (this.phase == "downloading") window.download.stop();
        if (this.phase == "downloading" || this.phase == "unpacking")
          this.$store.dispatch("deletePackage");
        this.phase = "";
        this.isLoading = false;
        this.contentLoader = true;
        this.buttonsDisabled = false;
      }
    },
    start() {
      if (this.region !== null) {
        this.$store.commit("setActiveRegion", { region: this.region });
        this.$router.push({
          path: "/home/",
        });
      }
    },
    profile() {
      this.$router.push({ path: "/profile/" });
    },
    logout(keep) {
      // Сброс параметра аутентификации
      // this.$store.commit("resetAuth", { auth: false });
      let keepUser = typeof keep === "boolean" ? keep : false;
      this.$store
        .dispatch("logout", keepUser)
        .then((result) => {})
        .catch((err) => {})
        .finally(() => {
          this.$router.push({ path: "/login/" });
        });
    },
    postpone() {
      if (this.$store.state.modified) {
        this.homeErrors.splice(0);
        this.contentLoader = false;
        this.loadContent();
      } else {
        this.homeErrors.splice(0);
        this.homeErrors.push("Загрузка контента необходима.");
      }
    },
    getOfflineContent() {
      if (window.navigator.onLine) {
        this.homeErrors.splice(0);
        this.phase = "packing";
        this.loadingProgress = "";
        this.buttonsDisabled = true;
        this.$store.commit("increaseDownloadsStartedCount");
        console.log("Insomnia on");
        window.plugins.insomnia.keepAwake();
        this.$store
          .dispatch("getOfflineContent")
          .then((response) => {
            if (this.phase == "packing") {
              this.$store.commit("setPackageTS", {
                ts: response.data.ts,
              });
              if (window.downloadsStartedCount == 1) {
                this.downloadOfflineContent(response.data);
              }
              this.$store.commit("decreaseDownloadsStartedCount");
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.contentLoader = true;
            this.buttonsDisabled = false;
            this.phase = "";
            this.homeErrors.push("Соединение прервалось");
            console.log("Insomnia off");
            window.plugins.insomnia.allowSleepAgain();
          });
      } else {
        this.homeErrors.splice(0);
        this.homeErrors.push("Необходимо подключение к сети");
      }
    },

    downloadOfflineContent(data, fragment = 1) {
      const ts = data.ts;
      const fragments = "fragments" in data ? data.fragments : null;
      this.homeErrors.splice(0);
      var ctx = this;

      let offset = 0;
      let total = 0;
      console.log(`fragment=${fragment}`);
      for (let i = 0; i < fragments.length; i++) {
        total += fragments[i];
        console.log(`${i + 1}<${fragment} - ${i + 1 < fragment}`);
        if (i + 1 < fragment) {
          offset += fragments[i];
          console.log(`incremented offset=${offset}`);
        }
      }
      console.log(`offset=${offset}`);
      console.log(`total=${total}`);

      ctx.phase = "downloading";
      ctx.anim.playSegments([25, 25 + 24], true);

      var uriString = encodeURI(
        `${CONFIG.CONTENT_API_URL}/offline?ts=${ts}&fragment=${fragment}`
      );
      var fileName = "content.zip";

      window.resolveLocalFileSystemURL(
        cordova.file.dataDirectory,
        function (fileSystem) {
          fileSystem.getFile(fileName, { create: true }, function (targetFile) {
            var downloader = new BackgroundTransfer.BackgroundDownloader();
            var download = (window.download = downloader.createDownload(
              uriString,
              targetFile
            ));
            window.downloadPromise = download.startAsync().then(
              function () {
                const progressValue =
                  (offset + fragments[fragment - 1]) / total;
                ctx.loadingProgress =
                  ((offset + fragments[fragment - 1]) / 1024 / 1024).toFixed(
                    2
                  ) +
                  " / " +
                  (total / 1024 / 1024).toFixed(2) +
                  "Mb";
                ctx.border.attr(
                  "stroke-dasharray",
                  progressValue * ctx.borderLength + " 1000"
                );
                ctx.border.addClass("animated");
                ctx.phase = "unpacking";
                ctx.anim.playSegments([50, 50 + 24], true);
                backgroundtask.start(() => {
                  zip.unzip(
                    targetFile.nativeURL,
                    cordova.file.dataDirectory,
                    function () {
                      const progressValue =
                        (offset + fragments[fragment - 1]) / total;
                      ctx.borderCompl.attr(
                        "stroke-dasharray",
                        progressValue * ctx.borderLength + " 1000"
                      );
                      ctx.border.removeClass("animated");

                      window.fileStorage.delete("content.zip");

                      if (fragment == fragments.length) {
                        if (ctx.phase == "unpacking") {
                          ctx.$store.dispatch("sendContentLastUpdateTime", {
                            ts: Math.round(+new Date() / 1000),
                          });
                          console.log("Insomnia off");
                          window.plugins.insomnia.allowSleepAgain();
                          ctx.loadContent(true);
                        }
                      } else {
                        ctx.downloadOfflineContent(
                          { ts, fragments },
                          fragment + 1
                        );
                      }
                    }
                  );
                });
              },
              function (error) {
                window.fileStorage.delete("content.zip");
                ctx.contentLoader = true;
                ctx.buttonsDisabled = false;
                ctx.phase = "";
                console.log("Insomnia off");
                window.plugins.insomnia.allowSleepAgain();
              },
              function (event) {
                const progressValue = (offset + event.bytesReceived) / total;

                ctx.loadingProgress =
                  ((offset + event.bytesReceived) / 1024 / 1024).toFixed(2) +
                  " / " +
                  (total / 1024 / 1024).toFixed(2) +
                  "Mb";
                ctx.border.attr(
                  "stroke-dasharray",
                  progressValue * ctx.borderLength + " 1000"
                );
              }
            );
          });
        },
        this.onLoadError
      );
    },

    onLoadError(err) {
      console.log("Home -> onLoadError");
    },
  },
};
</script>

<style lang="scss">
.start {
  .form-region,
  .content-loader {
    margin-bottom: 25px;

    .loading-info {
      display: flex;
      align-items: center;
    }

    .loader-graphics {
      margin: 0 8px 0 0 !important;
    }

    .loadingProgress {
      color: #1ea434;
    }

    .animated {
      animation: 0.3s infinite alternate pulse;
    }
  }
}

@keyframes pulse {
  from {
    stroke: #1ea434;
  }

  50% {
    stroke: #1ea434;
  }

  to {
    stroke: #005f20;
  }
}
</style>
