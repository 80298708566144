<template>
<span class="custom-checkbox">
<!--<input :name="name" :id="id" :checked='isJeansLogin' @input='$emit("input", $event.target.checked)' type="checkbox" />-->
<input :name="name" :id="id" v-model="innerData" type="checkbox" />
<span class='box'><span class='tick'></span></span>
</span>

</template>

<script>
export default {
  name: "custom-checkbox",
  props: ['name','id','isJeansLogin'],
    data: function(){
        return {
            innerData: this.isJeansLogin
        }
    },
    created() {
        this.innerData = this.isJeansLogin; 
    },
    watch: {
        innerData: function(){
            this.$emit('input', this.innerData)
        }
    }
 }
</script>

<style>
.custom-checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.9375rem;
}

.custom-checkbox > .box {
    position: relative;
    display: block;
    width: 1.5625rem;
    height: 1.5625rem;
    border-radius:5px;
    background-color: white;
}

.custom-checkbox > .box > .tick {
    position: absolute;
    left: 0.1875rem;
    top: 0.25rem;
    width: 1.3rem;
    height: 0.7rem;
    border-bottom: 6px solid #007700;
    border-left: 6px solid #007700;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: none;
}
.custom-checkbox > input {
    height:100%;
}
.custom-checkbox > input:checked + .box > .tick {
    display: block;
}

.custom-checkbox > input {
    position: absolute;
    outline: none;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    opacity: 0;
    z-index: 1;
}

</style>