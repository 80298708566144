<template>
  <layout-auth>
    <div class="login wrapper formwidth">
      <form class="form-login" @submit.prevent="validateBeforeSubmit()">
        <div v-if="loginErrors.length">
          <p
            class="LoginError"
            :key="key"
            v-for="(error, key) in loginErrors"
            v-html="error"
          ></p>
        </div>
        <div class="Inputfield Inputfield_login_name">
          <input
            name="name"
            v-model="name"
            v-validate="{ required: true, regex: /^[\\.\\@A-Za-z0-9_-]+$/ }"
            type="text"
            id="inputName"
            placeholder="Логин"
          />
          <span v-show="errors.has('name')" class="help is-danger">
            <span class="small">{{ errors.first("name") }}</span>
          </span>
        </div>
        <div class="Inputfield Inputfield_isJeansLogin">
          <label for="isJeansLogin">Вы пользователь Jeans?</label>
          <custom-checkbox
            name="isJeansLogin"
            id="isJeansLogin"
            :isJeansLogin="isJeansLogin"
            v-model="isJeansLogin"
          />
        </div>
        <div class="Inputfield Inputfield_login_pass">
          <input
            name="password"
            v-model="password"
            v-validate="'required'"
            type="password"
            id="inputPassword"
            placeholder="Пароль"
          />
          <span v-show="errors.has('password')" class="help is-danger">
            <span class="small">{{ errors.first("password") }}</span>
          </span>
        </div>
        <div
          class="Inputfield InputfieldSubmit"
          v-bind:class="{ active: isLoading }"
        >
          <button class="btn btn-lg btn-primary btn-block" type="submit">
            Log in
          </button>
        </div>
      </form>
      <div>
        <p v-if="wrongCredentials == 'wrong'">
          <a class="with-icon pass-get" href="/restore" @click.prevent="restore"
            >Забыли пароль? Восстановите доступ.</a
          >
        </p>
        <p v-if="wrongCredentials == 'expired'">
          <a class="with-icon pass-get" href="/restore" @click.prevent="restore"
            >Получите новый временный пароль.</a
          >
        </p>
      </div>
    </div>
  </layout-auth>
</template>

<script>
import CustomCheckbox from "./CustomCheckbox";
import LayoutAuth from "@/Layouts/LayoutAuth.vue";

export default {
  name: "Login",
  components: {
    "custom-checkbox": CustomCheckbox,
    LayoutAuth,
  },
  data() {
    return {
      name: "",
      password: "",
      isJeansLogin: true,
      loginErrors: [],
      wrongCredentials: "",
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    validateBeforeSubmit() {
      this.$validator
        .validateAll()
        .then(this.login())
        .catch(function (e) {});
    },
    restore() {
      this.$router.push({
        path: "/restore/",
        query: { username: this.name, isJeansLogin: this.isJeansLogin },
      });
    },
    login() {
      if (this.$validator.errors.items.length) return;
      this.loginErrors.splice(0);
      this.wrongCredentials = false;
      this.isLoading = true;

      this.$store
        .dispatch("login", {
          password: this.password,
          cordova: window.cordova ? true : false,
          username: this.name,
          isJeansLogin: this.isJeansLogin,
          channel: "ontrade",
        })
        .then((response) => {
          this.loginSuccessful(response);
        })
        .catch((err) => {
          this.loginFailed(err);
        });
    },

    loginSuccessful(response) {
      this.isLoading = false;
      this.loginErrors.splice(0);
      this.wrongCredentials = false;
      this.$router.push({ path: "/" });
    },

    loginFailed(error) {
      this.isLoading = false;
      if (typeof error === "string") {
        this.loginErrors.push(error);
      } else if (!error.response) {
        this.loginErrors.push("Ошибка соединения");
      } else {
        // http status code
        const code = error.response.status;
        // response data
        const errordata = error.response.data;

        switch (code) {
          case 401:
            if (errordata == "password expired") {
              this.loginErrors.push("Время действия пароля истекло");
              this.wrongCredentials = "expired";
            } else if (errordata == "login permitted") {
              this.loginErrors.push(
                "Превышено допустимое количество попыток. Попробуйте снова через 10 минут"
              );
              this.wrongCredentials = "wrong";
            } else {
              this.loginErrors.push(
                "<span class='small'>Авторизация не прошла.<br/>Неверное сочетание логина, пароля и ответа на вопрос «Вы пользователь Jeans?».<br/>Проверьте правильность введенных данных.</span>"
              );
              this.wrongCredentials = "wrong";
            }

            break;
          default:
            this.loginErrors.push("Ошибка соединения");
            break;
        }
      }
      this.$store.dispatch("logout");
    },
  },
};
</script>
