import Vue from "vue";
import Router from "vue-router";
import Content from "@/components/Content";
import Login from "@/components/Login";
import Profile from "@/components/Profile";
import Restore from "@/components/Restore";
import Home from "@/components/Home";
import store from "@/store";
import appContent from "@/assets/app";

Vue.use(Router);
Vue.use(store);

const router = new Router({
  mode: window.cordova ? "hash" : "history",
  store,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/restore",
      name: "Restore",
      component: Restore,
    },
    {
      path: "/:path*",
      name: "Content",
      component: Content,
    },
  ],
});

export default router;

router.beforeEach((to, from, next) => {
  if (to.path === "/") appContent.pageURL = "";

  if (to.path === "/login/") {
    if (store.state.authenticated) {
      next({ path: "/" });
    } else {
      next();
    }
  } else if (to.path === "/" || to.path === "/profile/") {
    if (store.state.authenticated) {
      next();
    } else {
      next({ path: "/login/" });
    }
  } else if (to.path === "/restore/") {
    next();
  } else {
    if (store.state.authenticated) {
      next();
    } else {
      next({ path: "/login/" });
    }
  }
});
