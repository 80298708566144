<template>
  <div id="stage" class="start">
    <div class="orientation-warning">
      <span>Поддерживается только портретная ориентация</span>
    </div>
    <div id="content"></div>
    <div id="loader" class="hide"><span class="loaderamount"></span></div>
    <div id="menu"></div>
    <div id="topmenu" class="hide"></div>
    <div class="footer">
      <span class="bottom-title"
        >SALES FOLDER <small>/</small> {{ $store.state.channel }}</span
      >
    </div>
  </div>
</template>

<script>
import Content from "@/assets/app";

export default {
  name: "Content",
  data() {
    return {
      msg: "Content",
      isLoading: false,
      isPageLoading: false,
      topMenuVisible: true,
    };
  },
  mounted() {
    Content.init();
  },
};
</script>

<style lang="scss">
</style>
